import React from "react"
import PropTypes from "prop-types"
import loadable from "@loadable/component"

import withLocation from "../../components/withLocation"
import Layout from "../../components/layout"
import { AuthenticatedRoute } from "../routing/Routes"
import WorkoutSEO from "../../components/WorkoutSEO"

const LoadableGroupWorkout = loadable(() => import("./GroupWorkout"))

const GroupWorkoutWrapper = ({ search, pageContext }) => {
  const { gid } = search
  return <Layout>
    <WorkoutSEO workoutVideo={pageContext}/>
    <AuthenticatedRoute component={LoadableGroupWorkout} roomId={gid} showLoader={true}/>
  </Layout>
}

GroupWorkoutWrapper.propTypes = {
  search: PropTypes.object,
  pageContext: PropTypes.object
}

export default withLocation(GroupWorkoutWrapper)